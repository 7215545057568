@use "../../App.scss" as app;
.checkout {
    &__review {
        @include app.checkout-box;
        &-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
        }
        &-box {
            padding: 23px 17px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            background-color: #F3F3F3;
            margin-top: 31px;
            gap: 21px;
            &-title {
                display: flex;
                flex-direction: column;
                gap: 4px;
                font-weight: 400;
                font-size: 15px;
                line-height: 140%;
            }
            &-numbers {
                margin-left: auto;
                display: flex;
                flex-direction: column;
                gap: 3px;
                text-align: end;
                span {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 140%;
                }
            }
        }
        &-amount {
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            gap: 6px;
            div {
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                span {
                    color: #8e8e8e;
                }
            }
        }
        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: var(--secondary-color);
            margin-top: 60px;
            a {
                text-decoration: none;
                color: #0038ff;
                margin-left: 3px;
            }
        }
        &-button {
            margin-top: 16px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            button:first-of-type {
                @include app.light-no-outline-btn;
            }
            button:last-of-type {
                @include app.light-outline-btn;
            }
        }
        &-loading{
            display: flex;
            place-content: center;
            padding-top: 20px;
        }
    }
}

@media only screen and (max-width: 1023px) {
    .checkout__review {
        &-box {
            padding: 25px 15px;
            margin-top: 29px;
            gap: 15px;
            img {
                width: 34px;
                height: 34px;
            }
        }
        p {
            margin-top: 35px;
           
        }
        &-button{
            margin-top: 80px;
        }
    }
}
