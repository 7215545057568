@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --main-color: #fff;
  --secondary-color: #000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-color);
  background-color: var(--main-color);
  line-height: 1.2;
  min-height: calc(100vh - 69px);
  background-color: #F3F3F3;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: calc(100vh - 69px);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 69px);
}

.checkout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

@mixin checkout-box {
  padding: 36px 30px;
  background-color: var(--main-color);
  border: 2px solid #E8E8E8;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  display: flex;
  background-clip: padding-box;
  flex-direction: column;
  position: relative;
  width: 428px;

  &::after {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    content: '';
    z-index: -1;
    border-radius: 10px;
  }
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
}

@keyframes rotate-gradient {
  to {
    transform: rotate(360deg);
  }
}

@mixin light-outline-btn {
  outline: none;
  border: none;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
  color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 37.5px;

  &:hover {
    background-color: var(--secondary-color);
    color: var(--main-color);
  }
}

@mixin light-no-outline-btn {
  outline: none;
  border: none;
  padding: 5px 16px;
  background-color: transparent;
  border: 1px solid #F3F3F3;
  border-radius: 10px;
  color: var(--secondary-color);
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.3s ease-in-out;
  display: block;
  position: relative;
  height: 37.5px;

  &:before {
    content: '';
    border-bottom: 2px solid var(--secondary-color);
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    margin: 0 auto;
    width: 0;
  }

  &:hover:before {
    width: 100%;
    padding: 0;
  }
}

@mixin dark-btn {
  outline: none;
  border: none;
  border: 1px solid var(--main-color);
  background: var(--main-color);
  border-radius: 10px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: var(--secondary-color);
  padding: 6.5px 19px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: var(--main-color);
  }
}

@mixin dark-outline-btn {
  outline: none;
  border: none;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  padding: 12px 32px;
  background-color: transparent;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  color: var(--main-color);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--main-color);
    color: var(--secondary-color);
  }
}

@mixin dark-no-outline-btn {
  outline: none;
  border: none;
  padding: 5px 16px;
  background-color: transparent;
  border-color: transparent;
  color: var(--main-color);
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.3s ease-in-out;
  border: none;
  display: block;
  position: relative;

  &:before {
    content: '';
    border-bottom: 2px solid var(--main-color);
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    margin: 0 auto;
    width: 0;
  }

  &:hover:before {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1023px) {
  body {
    min-height: calc(100vh - 10px);
  }

  .checkout {
    margin-inline: 20px;
    width: calc(100% - 40px);
    &__error {
      width: 100%;
    }
  }

}
