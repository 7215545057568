@use '../../App.scss' as app;

.checkout {
  &__instructions {
    @include app.checkout-box;
    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
    }
    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }
    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 17px;
      margin-bottom: 22px;
      li {
        display: flex;
        gap: 22px;
        align-items: center;
        svg {
          min-width: 40px;
          min-height: 40px;
          filter: invert(100%);
        }
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
      }
    }
    &-actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      button {
        @include app.light-outline-btn;
        align-self: flex-end;
      }
    }
  }
}
