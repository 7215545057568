@use '../../App.scss' as app;

.refund {
  height: 526px;
  @include app.checkout-box;

  &-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 28px;
  }
  &-loading {
    display: flex;
    place-content: center;
  }
  svg {
    align-self: center;
  }
  &-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    margin: 26px 0;
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  .refund {
    svg {
      path {
        fill: black;
        stroke: black;
      }
    }
  }
}
