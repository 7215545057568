@use "../../App.scss" as app;
.checkout {
    &__process {
        min-height: 528px;
        @include app.checkout-box;
       
        &-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
        }
        svg {
            align-self: center;
            margin: 26px 0;
        }
        &-header {
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
        }
        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            margin-top: 28px;
            margin-bottom: auto;
        }
    }
}

@media only screen and (max-width: 1023px) {
    .checkout__process{
        svg{
            filter: invert(100%);
        }
    }   
}
