@use '../../App.scss' as app;
.checkout {
  &__deposit {
    overflow: hidden;
    border-radius: 10px;
    section {
      @include app.checkout-box;
      &::after {
        top: -35%;
        left: -45%;
        width: 175%;
        height: 175%;
        animation: rotate-gradient linear 5s infinite;
      }
    }
    main {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 33px;
      div {
        display: flex;
        flex-direction: column;
        gap: 6px;
        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 700;
          font-size: 15px;
          line-height: 140%;
          span {
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            cursor: pointer;
          }
        }
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          word-wrap: break-word;
          max-width: calc(100vw - 110px);
        }
      }
    }
    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      span {
        font-weight: 700;
        font-size: 35px;
        line-height: 140%;
        color: #ff6700;
      }
    }
    &-box {
      border-radius: 10px;
      padding: 16px 21px;
      margin-top: 15px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      background-color: #f3f3f3;
      gap: 8px;
      div {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        justify-content: space-between;
        span {
          display: flex;
          align-items: center;
          gap: 5px;
          text-align: end;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #8e8e8e;
    }
    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 24px;
      margin-top: 20px;
      div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
      }
      li {
        font-weight: 700;
        font-size: 15px;
        line-height: 140%;
        white-space: nowrap;
      }
      span {
        width: 100%;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }
    &-button {
      margin-top: 42px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      button:first-of-type {
        @include app.light-no-outline-btn;
      }
      button:last-of-type {
        @include app.light-outline-btn;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .checkout__deposit {
    section {
      &::after {
        width: 300%;
        height: 300%;
        top: -80%;
        left: -70%;
      }
    }
    &-box {
      margin-bottom: 15px;
    }
    main {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 33px;
      div {
        display: flex;
        flex-direction: column;
        gap: 6px;
        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #121212;
          font-weight: 700;
          font-size: 15px;
          line-height: 140%;
          span {
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
          }
        }
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: black;
          word-wrap: break-word;
          max-width: calc(100vw - 110px);
        }
      }
    }
    &-button {
      margin-top: 73px;
    }
  }
}
