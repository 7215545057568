@use "../../App.scss" as app;

.checkout {
    &__error {
        height: 526px;
        @include app.checkout-box;

        &-header {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            margin-bottom: 28px;
        }
        svg {
            align-self: center;
            path {
                fill: black;
                stroke: black;
            }
        }
        &-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            margin: 26px 0;
        }
        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
        }
    }
}