.header {
    display: flex;
    align-items: center;
    max-width: 1178px;
    margin: 0 auto;
    padding: 0 48px;
    width: 100%;
    justify-content: space-between;
    margin-top: 36px;
    color:white;

    &__logo {
        filter: invert(100%);
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 32px;

        a {
            text-decoration: none;
            color: inherit;
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
        }
    }
    &__help {
        display: none;
    }
}

@media only screen and (max-width: 1023px) {
    .header {
        margin-inline: 20px;
        margin-top: 12px;
        padding: 0;
        width: calc(100% - 40px);
        &__logo {
            height: 21.8px;
            width: 64px;
        }
        &__help {
            display: none;
        }
    }
}
