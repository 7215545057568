@use '../../App.scss' as app;
.checkout {
  &__crypto {
    @include app.checkout-box;
    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 20px;
    }
    &-amount {
      font-weight: 700;
      font-size: 35px;
      line-height: 140%;
      margin-bottom: 8px;
      sup {
        font-weight: 700;
        font-size: 15px;
        line-height: 140%;
      }
    }
    p {
      font-style: italic;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: var(--secondary-color);
    }
    &-box {
      display: flex;
      flex-direction: column;
      margin-top: 35px;
      gap: 8px;
      section {
        display: flex;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
        &:last-of-type {
          gap: 28px;
        }
        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 140%;
        }
        div {
          display: flex;
          flex: 1;
          width: 100%;
          align-items: center;
          gap: 16px;
          border-radius: 6px;
          img {
            width: 20px;
            height: 20px;
          }
          header {
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            flex: 1;
          }
        }
        main {
          display: flex;
          align-items: center;
          gap: 5px;
          flex: 1;
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            flex: 1;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            width: 100%;
            height: 37.5px;
            border: 1px solid var(--main-color);
            border-radius: 6px;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: var(--main-color);
            }
            &.active {
              background-color: var(--main-color);
              border: 2px solid #F3F3F3;
            }
          }
        }
      }
      &-action {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
    &-approx {
      margin-top: 45px;
      display: flex;
      flex-direction: column;
      gap: 13px;
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        display: flex;
        align-items: center;
        div {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--main-color);
          margin-left: 8px;
          font-weight: 700;
          font-size: 13px;
          line-height: 18px;
          cursor: pointer;
        }
      }
      &-amount {
        display: flex;
        align-items: center;
        gap: 16.5px;
        font-weight: 400;
        font-size: 25px;
        line-height: 33px;
      }
    }
    button {
      @include app.light-outline-btn;
      align-self: flex-end;
      margin-top: 17px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .checkout__crypto {
    &-box {
      section {
        div {
          svg {
            filter: invert(100%);
          }
        }
      }
    }
    &-approx {
      span {
        div {
          background: black;
          color: white;
        }
      }
    }
  }
}
